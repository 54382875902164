/** @typedef {ReturnType<state>} State */
/** @typedef {Record<string, (state: State, data) => void>} Mutation */

export const state = () => ({
  dinkSubscriptions: {},
});

export const getters = {
}

/**
 * @type {Mutation} 
 */
export const mutations = {
    SET_DINK_SUBSCRIPTIONS(state, payload) {
        state.dinkSubscriptions = { ...payload }
    }
};

export const actions = {
  async getDinkSubscriptions({ state, commit }, data) {
    commit('SET_LOADING', true, { root: true });
    try {
      const res = await this.$axios.get(`organizations/${data.organizationID}/dinkSubscriptions`, { data });
      commit('SET_DINK_SUBSCRIPTIONS', res.data);
    //   return res.data || [];
      return res.data;
    } catch (err) {
      console.error(err);
      commit("user/SHOW_MESSAGE", {
        color: "error",
        message: err.response.data.message,   
      })
    }
  },
};
